/* eslint-disable no-unused-vars */
/* ICONS */
import { IoHeadsetOutline } from "react-icons/io5";
import { HiUsers } from "react-icons/hi2";
import { BsEmojiSmile } from "react-icons/bs";

const navMenu = [
  /* {
    id: 2,
    link: "/company",
    icon: FiShoppingCart,
    name: "Companies",
  }, */

  {
    id: 11,
    link: "/registration-form",
    icon: HiUsers,
    name: "Register your interest",
  },
  {
    id: 3,
    link: "/event",
    icon: BsEmojiSmile,
    name: "Events",
    secondary: "/event/add-event",
  },
  /* {
    id: 5,
    link: "/sponsor",
    icon: BiCategory,
    name: "Sponsor",
  }, */
  /* {
    id: 6,
    link: "/members",
    icon: TbCategoryPlus,
    name: "Members",
  }, */
  /* {
    id: 7,
    link: "/sector",
    icon: LuTags,
    name: "Sectors",
  }, */
  {
    id: 10,
    link: "/contact-us",
    icon: IoHeadsetOutline,
    name: "Contact Us",
  },
  {
    id: 10,
    link: "/help-desk",
    icon: IoHeadsetOutline,
    name: "Help Desk",
  },
  {
    id: 11,
    link: "/email-notification",
    icon: IoHeadsetOutline,
    name: "Email Notification",
  },
  /* {
    id: 10,
    link: "/news-letter",
    icon: IoHeadsetOutline,
    name: "News Letter",
  }, */
];

export default navMenu;
